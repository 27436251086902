/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createJob = /* GraphQL */ `
  mutation CreateJob($limit: Int, $nextToken: String, $input: jobNodeInput) {
    createJob(limit: $limit, nextToken: $nextToken, input: $input) {
      items {
        contentType
        createdAt
        id
        jobIdentity
        jobName
        jobOwner
        jobStatus
        languageSource
        languageTargets
        sourceKey
        sourceStatus
        translateCallback
        translateKey
        translateStatus
      }
      nextToken
    }
  }
`;
